import React from 'react';
import DashBoard from './structure/layout/company/Deshboard';

function App() {
  return (
    <DashBoard/>
  );
}

export default App;
