import React from 'react';
import { HomeOutlined } from "@ant-design/icons";
import Welcome from './../../structure/pages/welcome';
import ReportCoordinatorPaymentPeriod from '../../structure/pages/payment/ReportCoordinatorPaymentPeriod';
import ReportUECE from '../../structure/pages/payment/ReportUECE';
import ReportCFO from '../../structure/pages/payment/ReportCFO';
import ReportDebit from '../../structure/pages/payment/ReportDebit';
import ListStudentsPaymentRecovery from '../../structure/pages/payment-recovery/ListStudentsPaymentRecovery';

const routers = [
  {
    label: "Início",
    path: "/",
    component: <Welcome/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: true,
  },
  {
    label: "Repasse Coord.",
    path: "/repasse-coordenadores",
    component: <ReportCoordinatorPaymentPeriod/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: false,
  },
  {
    label: "Repasse UECE",
    path: "/repasse-uece",
    component: <ReportUECE/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: false,
  },
  {
    label: "Repasse CFO",
    path: "/repasse-cfo",
    component: <ReportCFO/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: false,
  },
  {
    label: "Inadimplência",
    path: "/inadimplencia",
    component: <ReportDebit/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: false,
  },
  {
    label: "Cobrança - Mês",
    path: "/payment-month",
    component: <ListStudentsPaymentRecovery/>,
    hiddenMenu: false,
    icon: <HomeOutlined/>,
    client: true,
    owner: false, // owner company
    admin: false, // authenticate admin
    onlyDev: false, // gnnc
    exact: false,
  },
];
export default routers;
