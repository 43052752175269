import React, {useState} from "react";
import 'antd/dist/antd.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import routers from "./../../../resources/router/router";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

export default function DashBoard() {

  const [fullYear] = useState(new Date().getFullYear());
  const [collapsed, setCollapsed] = useState(false);
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;

  const onCollapse = (collapsed) => {
    setCollapsed( collapsed );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Router>
        <Sider theme="light" collapsibleX collapsed={collapsed} onCollapse={onCollapse} style={styles.sider}>
          <div style={styles.logo}>
            Reports
          </div>
          <Menu theme="light" mode="inline" className="menuLeft" inlineIndent={0}>

            {routers.map((el, index) => {
              if( el.hiddenMenu === true) return (null);
              return (
                <Menu.Item key={index} style={styles.menuItem}>
                  <Link to={el.path} key={index} style={styles.link} onClick={()=>null} exact={el.exact}>
                    {el.label}
                  </Link>
                </Menu.Item>
            )})}

            {/* <Menu.Item key="2" icon={<DesktopOutlined />}>
              Option 2
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="User">
              <Menu.Item key="3">Tom</Menu.Item>
              <Menu.Item key="4">Bill</Menu.Item>
              <Menu.Item key="5">Alex</Menu.Item>
            </SubMenu>
            <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
              <Menu.Item key="6">Team 1</Menu.Item>
              <Menu.Item key="8">Team 2</Menu.Item>
            </SubMenu>
            <Menu.Item key="9" icon={<FileOutlined />}>
              Files
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header style={styles.header} >
            Academia Cearense de Odontologia
          </Header>
          <Content>
          
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb> */}

            <Switch>
              {[...routers].reverse().map( (el,index) => <Route path={el.path} component={() => el.component} key={index} exact={el.exact }></Route>)}
            </Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <a href="https://gnnc.com.br" target="_blank">
            Daybyday Reports
            </a> © {fullYear} Created by Natan Cabral 
          </Footer>
        </Layout>
      </Router>
    </Layout>
  );
}

const styles = {
  logo: {
    padding: '17px 5px 5px 25px',
    // textAlign: 'center',
    fontWeight: 400,
    fontSize: 18,
    height: 64,
    backgroundColor: '#330840',
    color: 'white',
  },
  sider: {
  },
  header: {
    padding: 0,
    paddingLeft: 25,
    margin: 0,
    height: 64,
    backgroundColor: '#330840',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  menuItem: {
    textDecoration: 'none',
    paddingLeft: 25,
  }
};
