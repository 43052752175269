import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import ApiServer from "../../../resources/conn/ApiServer";
import { DatePicker, InputNumber, Select, Input, Alert } from 'antd';
import tables2json from "tables2json";

export default function ReportCoordinatorPaymentPeriod() {

  const [showReport, setShowReport] = useState(false);
  const [listFinancial, setListFinancial] = useState([]);
  const [listFinancialNoPay, setListFinancialNoPay] = useState([]);
  const [courseName, setCourseName] = useState('');

  // const monthFull = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho", "Agosto","Setembro","Outubro","Novembro","Dezembro"];
  //const month = ["January","February","March","April","May","June","July", "August","September","October","November","December"];
  const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago", "Set", "Out", "Nov", "Dez"];
  const [downloadFPDFile, setDownloadFPDFile] = useState(false);
  const [downloadFPDFileLink, setDownloadFPDFileLink] = useState('');

  const [percentsAndDiscounts, setPercentsAndDiscounts] = useState({
    getParts: [
      // {label: 'Repasse ACO', valuePercent: 0.1, valueFixed: 0},
      // {label: 'Repasse UECE', valuePercent: 0.1, valueFixed: 0},
      // {label: 'Coordenador', valuePercent: 0.6, valueFixed: 0},
    ],
    discountPaymentCoordinator: [
      // {label: 'Domingo', valuePercent: 0, valueFixed: 300.0 },
      // {label: 'Hotel', valuePercent: 0, valueFixed: 0 },
      // {label: 'Adiantamento', valuePercent: 0, valueFixed: 0 },
      // {label: 'Passagens', valuePercent: 0, valueFixed: 0 },
      // {label: 'Transporte', valuePercent: 0, valueFixed: 0 },
      // {label: 'Material', valuePercent: 0, valueFixed: 0 },
      // {label: 'Papelaria', valuePercent: 0, valueFixed: 0 },
      // {label: 'Extras', valuePercent: 0, valueFixed: 0 },
    ]
  });

  const current = (value, showZero) => {

    if( showZero === true && ( !value || value === 0) ) return 'R$ 0,00';

    // var atual = 600000.00;
    // //com R$
    // var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    // //sem R$
    // var f2 = atual.toLocaleString('pt-br', {minimumFractionDigits: 2});
    // console.log(f);
    // console.log(f2);
    return value === 0 ? '' : value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  const dateToPtBr = (value) => {
    if( String(value).substr(0,4) === '0000') return '';
    // Split timestamp into [ Y, M, D, h, m, s ]
    const t = String(value).split(/[- :]/);    
    // Apply each element to the Date function
    // const d = new Date(Date.UTC(t[0], Number(t[1])-1, t[2], t[3], t[4], t[5]));
    return String(t[2]).substr(0,2) + ' ' + month[Number(t[1]-1)] + ' ' + t[0];
  }

  const getParcel = (el) => {
    let ACR = el.ACORDO === 1 ? ' AC ' : '';
    let POS = String(el.DESCRIPTION).indexOf(')');
    let MAT = String(el.DESCRIPTION).toLowerCase().indexOf('matr') > -1 ? 'Matrícula ' : '';
    let PAR = POS === -1 ? '' : String(el.DESCRIPTION).substr(1, POS);
    PAR = PAR.length > 10 ? '' : PAR;
    return MAT + PAR + ACR;
    // return String(el.DESCRIPTION).substr(1,String(el.DESCRIPTION).indexOf(')'))} {el.ACORDO === 1 ? 'ACORDO' : ''
  }

  const [values, setValues] = useState({
    current: {
      sumValueIn: 0,
      sumValueInPay: 0,
      sumValueInPayWork: 0,
      sumValueInNotPay: 0,
      sumValueInPayPercent: 0,  
      sumValueInNoPayPercent: 0,  
      partsResume: [
        //{label: '1', value: 1},
      ],
      valueRestDiscount: 0,  
    },
    past: {
      sumValueIn: 0,
      sumValueInPay: 0,
      sumValueInNotPay: 0,
      sumValueInPayWork: 0,
      sumValueInPayPercent: 0,  
      sumValueInNoPayPercent: 0,  
      partsResume: [
        //{label: '1', value: 1},
      ],
      valueRestDiscount: 0,  
    }, 
    future: {
      sumValueIn: 0,
      sumValueInPay: 0,
      sumValueInPayWork: 0,
      sumValueInNotPay: 0,
      sumValueInPayPercent: 0,  
      sumValueInNoPayPercent: 0,  
      partsResume: [
        //{label: '1', value: 1},
      ],
      valueRestDiscount: 0,    
    },
    // valores finais somados.
    final: [],
    // valores finais somados + custos.
    finalCosts: [],
  });

  const [past, setPast] = useState('');//` '2021-04-21' `;
  const [future, setFuture] = useState('');//` '2021-05-20' `;
  const [between, setBetWeen] = useState(''); //` BETWEEN '2021-04-21' AND '2021-05-20' `);

  const { RangePicker } = DatePicker;

  function onChangeDate(date, dateString) {
    //console.log(date, dateString);
    setPast(` '${String(dateString[0]).substr(0,10)}' `);
    setFuture(` '${String(dateString[1]).substr(0,10)}' `);
    setBetWeen(` BETWEEN '${String(dateString[0]).substr(0,10)}' AND '${String(dateString[1]).substr(0,10)}' `);
  }

  const { Option } = Select;
  const [idCourse, setIdCourse] = useState(0);//` '2021-04-21' `;
  const [listCourses, setListCourses] = useState([]);

  function onChangeIdCourse(value) {
    setIdCourse(value);
  }
  
  function onChangeIdCourseSelect( value, event ) {
    setIdCourse(value);
    setCourseName(event.children);
  }

  const getCourses = () => {
    const query = `select c.ID, c.NAME from dbd_project as c where c.ID_CLIENT = 1847 AND c.ACTIVE > 0 order by c.NAME limit 0,500`;
    const data = { query: query }
    new ApiServer().post('money/report/report-coordinator-payment','month-period', data ).then( el => {

      if(el.statusText === 'OK'){
        // console.log(el.data);
        setListCourses(el.data)
      }

    }).catch( err => setListCourses([]) );
    
  }

  let sql = '';

  const reportMonth = () => {

    if( future.length < 10 || past.length < 10 || idCourse === 0 ) {
      window.alert('Selecione uma Turma e o Período do relatório.');
      return;
    } 

    // + " select c.ID, c.NAME, c.ENROLLMENT "
    // + " FROM "
    // + " dbd_course_student as s "
    // + " LEFT JOIN dbd_client as c on (s.ID_CLIENT = c.ID) "
    // + " WHERE "
    // + " s.ID_PROJECT = 182 "

    let query = " "

    // Pagamentos antecipados
    + " ( "
    + " SELECT 0 as ORDER_LIST, 0 as isPast, 1 as isFuture, f.MIX, f.ID, f.VALUE_IN, f.VALUE_IN_PAY, f.NUMBER, f.NUMBER_FINAL_PAY, "
    + " f.DATE_END, f.DATE_FINAL, f.DATE_CANCELED, f.PAY_TYPE, p.NAME as COURSE_NAME, c.NAME as CLIENT_NAME, "
    + " MONTH(f.DATE_END) as MONTH, MONTH(f.DATE_FINAL) as MONTH_PAY, "
    + " (CASE WHEN INSTR(f.DESCRIPTION, 'ACORDO') > 0 THEN 1 ELSE 0 END) as ACORDO, "
    + " SUBSTR(f.DESCRIPTION,0,10) as PARC, "
    + " f.DESCRIPTION "
    + " FROM "
    + " dbd_financial as f "
    + " LEFT JOIN dbd_project as p on (p.ID = f.ID_PROJECT) "
    + " LEFT JOIN dbd_client as c on (c.ID = f.ID_CLIENT) "
    + " WHERE  "
    + ` f.ID_PROJECT = ${idCourse} `
    + " AND LEFT(f.DATE_CANCELED,4) like LEFT('0000-',4) "
    + " AND f.MIX <> 'FINANCIAL_NOTE' "
    + " AND f.MIX <> 'FINANCIAL_TRANS' "
    + " AND f.IS_TRANS = 0 "
    // pago no periodo 
    + ` AND f.DATE_FINAL ${between} `
    // porem antes do vencimento
    + ` AND f.DATE_END > ${future} `
    + " ORDER by CLIENT_NAME "
    + " ) "

    // Pagamentos no período
    + " UNION "

    + " ( "
    + " SELECT 1 as ORDER_LIST, 0 as isPast, 0 as isFuture, f.MIX, f.ID, f.VALUE_IN, f.VALUE_IN_PAY, f.NUMBER, f.NUMBER_FINAL_PAY,  "
    + " f.DATE_END, f.DATE_FINAL, f.DATE_CANCELED, f.PAY_TYPE, p.NAME as COURSE_NAME, c.NAME as CLIENT_NAME, "
    + " MONTH(f.DATE_END) as MONTH, MONTH(f.DATE_FINAL) as MONTH_PAY, "
    + " (CASE WHEN INSTR(f.DESCRIPTION, 'ACORDO') > 0 THEN 1 ELSE 0 END) as ACORDO, "
    + " SUBSTR(f.DESCRIPTION,0,10) as PARC, "
    + " f.DESCRIPTION "
    + " FROM "
    + " dbd_financial as f "
    + " LEFT JOIN dbd_project as p on (p.ID = f.ID_PROJECT) "
    + " LEFT JOIN dbd_client as c on (c.ID = f.ID_CLIENT) "
    + " WHERE  "
    + ` f.ID_PROJECT = ${idCourse} `
    + " AND LEFT(f.DATE_CANCELED,4) like LEFT('0000-',4) "
    + " AND f.MIX <> 'FINANCIAL_NOTE' "
    + " AND f.MIX <> 'FINANCIAL_TRANS' "
    + " AND f.IS_TRANS = 0 "
    // pagamento no mesmo período
    //+ ` AND f.DATE_FINAL ${between} `
    // vencimento no mesmo período
    + ` AND ( ( f.DATE_END ${between} AND LEFT(f.DATE_FINAL,4) like LEFT('0000-',4) ) OR ( f.DATE_FINAL ${between} AND f.DATE_END ${between} ) ) `
    // + " GROUP by f.ID_CLIENT,MONTH(f.DATE_FINAL) "
    + " ORDER by CLIENT_NAME "
    + " ) "

        // quando o relatório é passado e o pagamento foi feito no presente/futuro
        // Pagamentos no período (porém se o relatório for gerado para ver o passado, anula pagamento fora do período)
        // sendo assim cria uma ilusão de não pagamento, já que está olhando o passado.
        // 0 as VALUE_IN_PAY
        // '*' as NUMBER_FINAL_PAY --->>> para dizer que foi pago no futuro
        // '0000-00-00' as DATE_FINAL
        // '' as PAY_TYPE
        // 0 as MONTH_PAY
        + " UNION "
        + " ( "
        + " SELECT 1 as ORDER_LIST, 0 as isPast, 0 as isFuture, f.MIX, f.ID, f.VALUE_IN, 0 as VALUE_IN_PAY, f.NUMBER, '*' as NUMBER_FINAL_PAY,  "
        + " f.DATE_END, '0000-00-00' as DATE_FINAL, f.DATE_CANCELED, '' as PAY_TYPE, p.NAME as COURSE_NAME, c.NAME as CLIENT_NAME, "
        + " MONTH(f.DATE_END) as MONTH, 0 as MONTH_PAY, "
        + " (CASE WHEN INSTR(f.DESCRIPTION, 'ACORDO') > 0 THEN 1 ELSE 0 END) as ACORDO, "
        + " SUBSTR(f.DESCRIPTION,0,10) as PARC, "
        + " f.DESCRIPTION "
        + " FROM "
        + " dbd_financial as f "
        + " LEFT JOIN dbd_project as p on (p.ID = f.ID_PROJECT) "
        + " LEFT JOIN dbd_client as c on (c.ID = f.ID_CLIENT) "
        + " WHERE "
        + ` f.ID_PROJECT = ${idCourse} `
        + " AND LEFT(f.DATE_CANCELED,4) like LEFT('0000-',4) "
        + " AND f.MIX <> 'FINANCIAL_NOTE' "
        + " AND f.MIX <> 'FINANCIAL_TRANS' "
        + " AND f.IS_TRANS = 0 "
        // pagamento no futuro, para relatório sendo gerados com datas passadas 
        + ` AND f.DATE_FINAL > ${future} `
        // vencimento no mesmo período
        + ` AND f.DATE_END ${between} `
        // + " GROUP by f.ID_CLIENT,MONTH(f.DATE_FINAL) "
        + " ORDER by CLIENT_NAME "
        + " ) "

    // Pagamentos atrasados
    + " UNION "

    + " ( "
    + " SELECT 2 as ORDER_LIST, 1 as isPast, 0 as isFuture, f.MIX, f.ID, f.VALUE_IN, f.VALUE_IN_PAY, f.NUMBER, f.NUMBER_FINAL_PAY, "
    + " f.DATE_END, f.DATE_FINAL, f.DATE_CANCELED, f.PAY_TYPE, p.NAME as COURSE_NAME, c.NAME as CLIENT_NAME, "
    + " MONTH(f.DATE_END) as MONTH, MONTH(f.DATE_FINAL) as MONTH_PAY, "
    + " (CASE WHEN INSTR(f.DESCRIPTION, 'ACORDO') > 0 THEN 1 ELSE 0 END) as ACORDO, "
    + " SUBSTR(f.DESCRIPTION,0,10) as PARC, "
    + " f.DESCRIPTION "
    + " FROM "
    + " dbd_financial as f "
    + " LEFT JOIN dbd_project as p on (p.ID = f.ID_PROJECT) "
    + " LEFT JOIN dbd_client as c on (c.ID = f.ID_CLIENT) "
    + " WHERE  "
    + ` f.ID_PROJECT = ${idCourse} `
    + " AND LEFT(f.DATE_CANCELED,4) like LEFT('0000-',4) "
    + " AND f.MIX <> 'FINANCIAL_NOTE' "
    + " AND f.MIX <> 'FINANCIAL_TRANS' "
    + " AND f.IS_TRANS = 0 "
    // pago no período
    + ` AND f.DATE_FINAL ${between} `
    // pago atrasado.
    + ` AND f.DATE_END < ${past} `
    //+ " GROUP by f.ID_CLIENT,MONTH(f.DATE_FINAL) "
    + " ORDER by CLIENT_NAME "
    + " ) "

    //+ " ORDER by ORDER_LIST asc, CLIENT_NAME asc";
    + " ORDER by MONTH desc, CLIENT_NAME asc";

    sql = query;

    console.log('SQL:',sql);

    setDownloadFPDFile(false);

    // window.alert( query );

    const data = { query: query };
    new ApiServer().post('money/report/report-coordinator-payment','month-period', data ).then( el => {

      if(el.statusText === 'OK'){

        // console.log( el.data );
        createReportData( el.data );
        setListFinancial( el.data );
        reportNoPay();
        setShowReport(true);

        if(el.data.length > 0){
          setCourseName(el.data[0].COURSE_NAME);
          console.log(el.data[0].COURSE_NAME);
        }

        setTimeout(()=>{
          sendToServer( el.data.length > 0 ? el.data[0].COURSE_NAME : 'Nome do curso não encontrado');
          console.log('sendToServer()');
          setTimeout(()=>{
            console.log('setDownloadFPDFile()');
            setDownloadFPDFile(true);
          },5000);  
        },2000);
      
      }

    }).catch( err => setListFinancial([]) );
  }

  const createReportData = list => {

    const datas = {};

    ['current','past','future'].forEach( label => {

      const data = {
        sumValueIn: 0, // valor total a receber
        sumValueInPay: 0, // valor recebido/faturado
        sumValueInPayWork: 0, // valor sem juros ou multa, para não repassar para uece ou aco, pois existem despesas
        sumValueInPayFineAndCost: 0, // juros e multa (a diferença do esperado com o pago, esperado normalmente menor pois não tem juros)
        sumValueInNotPay: 0, // valor não recebido
        sumValueInPayPercent: 0, // porcentagem paga/faturado
        sumValueInNoPayPercent: 0, // porcentagem nao paga
        partsResume: [
          // {label: '', value: 0},
        ],
        partsResume2: [
          // {label: '', value: 0},
        ],
        coordinatorValue: 0,
        coordinatorDiscount: 0,
        valueRest: 0,
      }

      const isPay = (e) => {
        return e.isPast === 1 ? true : ( e.isPast === 1 ? true : ( e.MONTH_PAY > 0 ? true : false ) );
        // if(el.isPast === 1 ){
        //   return 'PG.C. ATRASO'; }
        // if(el.isFuture === 1){
        //   return 'PG.ANTECIPADO'; }
        // if(el.MONTH_PAY === 0){
        //   return ''; }
        // if(el.MONTH_PAY > 0){
        //   return 'PG. EM DIA'; } 
      }

      list.forEach( el => {

        if( label === 'current' && el.isPast === 0 && el.isFuture === 0 ){

          data.sumValueIn += isPay(el) ? el.VALUE_IN_PAY : el.VALUE_IN ;
          data.sumValueInPay += el.VALUE_IN_PAY ;
          // valor sem juros ou multas, para não repassar para uece ou aco, pois existem despesas
          data.sumValueInPayWork += isPay(el) ? ( el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN : el.VALUE_IN_PAY ) : 0 ; // el.VALUE_IN < el.VALUE_IN_PAY ? el.VALUE_IN : el.VALUE_IN_PAY ;
          // fine and cost
          data.sumValueInPayFineAndCost += isPay(el) ? (el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN_PAY - el.VALUE_IN : 0 ) : 0 ;
          // calc
          data.sumValueInNotPay = data.sumValueIn - data.sumValueInPay ;
          data.sumValueInPayPercent = (data.sumValueInPay / data.sumValueIn * 100) ; //@@@@@@
          data.sumValueInNoPayPercent = 100 - data.sumValueInPayPercent ;
        }

        if( label === 'past' && el.isPast === 1 && el.isFuture === 0 ){

          data.sumValueIn += isPay(el) ? el.VALUE_IN_PAY : el.VALUE_IN ;
          data.sumValueInPay += el.VALUE_IN_PAY ;
          // valor sem juros ou multas, para não repassar para uece ou aco, pois existem despesas
          data.sumValueInPayWork += isPay(el) ? ( el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN : el.VALUE_IN_PAY ) : 0 ; // el.VALUE_IN < el.VALUE_IN_PAY ? el.VALUE_IN : el.VALUE_IN_PAY ;
          // fine and cost
          data.sumValueInPayFineAndCost += isPay(el) ? (el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN_PAY - el.VALUE_IN : 0 ) : 0 ;
          // calc
          data.sumValueInNotPay = data.sumValueIn - data.sumValueInPay ;
          data.sumValueInPayPercent = (data.sumValueInPay / data.sumValueIn * 100) ;
          data.sumValueInNoPayPercent = 100 - data.sumValueInPayPercent ;
    
        }

        if( label === 'future' && el.isPast === 0 && el.isFuture === 1 ){

          data.sumValueIn += isPay(el) ? el.VALUE_IN_PAY : el.VALUE_IN ;
          data.sumValueInPay += el.VALUE_IN_PAY ;
          // valor sem juros ou multas, para não repassar para uece ou aco, pois existem despesas
          data.sumValueInPayWork += isPay(el) ? ( el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN : el.VALUE_IN_PAY ) : 0 ; // el.VALUE_IN < el.VALUE_IN_PAY ? el.VALUE_IN : el.VALUE_IN_PAY ;
          // fine and cost
          data.sumValueInPayFineAndCost += isPay(el) ? (el.VALUE_IN_PAY > el.VALUE_IN ? el.VALUE_IN_PAY - el.VALUE_IN : 0 ) : 0 ;
          // calc
          data.sumValueInNotPay = data.sumValueIn - data.sumValueInPay ;
          data.sumValueInPayPercent = (data.sumValueInPay / data.sumValueIn * 100) ;
          data.sumValueInNoPayPercent = 100 - data.sumValueInPayPercent ;
    
        }

      });

      // --------------------------------------------------------------------------
      // soma das despesas, descontos do coordenador, hotel, domingos etc
      if( label === 'current' ){

        data.coordinatorDiscount = percentsAndDiscounts.discountPaymentCoordinator.reduce( (acc, curr, index, arr) => {
          return curr.valueFixed > 0 ? acc + curr.valueFixed : ( curr.valuePercent > 0 ? acc * curr.valuePercent : acc ) ;
        },0);

      }

      // --------------------------------------------------------------------------
      // descontos
      data.valueLastExtractPart = 0; // último valor extraído e descontado
      data.valueRest = data.sumValueInPayWork; // o que restou do valor original depois dos descontos

      percentsAndDiscounts.getParts.forEach( el => {
        const v = Number(Number(data.valueRest * el.valuePercent).toFixed(2));
        data.valueLastExtractPart = v;
        data.valueRest -= v;
        data.valueRest = Number(Number(data.valueRest).toFixed(2));
        const d = {
          label: el.label, 
          value: v, 
          rest: data.valueRest, 
        };
        data.partsResume.push(d);
      });

      // --------------------------------------------------------------------------
      // 
      // VALUE_PERCENT_1 = Number( data.sumValueInPayWork * percentsAndDiscounts.getParts[0].valuePercent ) ;
      // VALUE_PERCENT_2 = Number( data.sumValueInPayWork - VALUE_PERCENT_1 ) * percentsAndDiscounts.getParts[1].valuePercent ;
  
      // data.valueRest = data.sumValueInPayWork - VALUE_PERCENT_1 - VALUE_PERCENT_2 ;
      // data.coordinatorValue = data.valueRest * percentsAndDiscounts.getParts[2].valuePercent;

      // data.partsResume = [
      //   {label: 'Repasse ACO', value: VALUE_PERCENT_1},
      //   {label: 'Repasse UECE', value: VALUE_PERCENT_2},
      //   {label: 'Coordenador', value: data.coordinatorValue },
      //   // final
      //   {label: 'Coord. (-) Despesas', value: data.coordinatorValue - data.coordinatorDiscount },
      //   {label: 'Repasse CEC', value: data.valueRest - data.coordinatorValue + data.coordinatorDiscount },
      // ];

      data.partsResume = [...data.partsResume, 
        // final
        // {label: 'Coord. (-) Despesas', value: data.valueLastExtractPart - (data.coordinatorDiscount )},
        // {label: 'Repasse CEC', value: data.valueRest - data.coordinatorValue + (data.coordinatorDiscount) },
        {label: 'Repasse CEC', value: data.valueRest - data.coordinatorValue},
        {label: 'Custos: Juros/Multa ¹', value: data.sumValueInPayFineAndCost },
      ];

      datas[label] = data;

    });

    // ---------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------
    // resumo final de todos os valores somados. Pagos em atraso, no mês e adiantado. Repasses somados. Etc.
    // ---------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------
    datas.final = [];
    ['current','past','future'].forEach( (label, index ) => {
      datas[label].partsResume.forEach( (el, indexEl) => {

        // no primeiro looping cria os elementos finais do resumo
        if( index === 0 ){

          datas.final.push({
            label: el.label,
            value: el.value,
          });
  
        // depois apenas soma com os valores, passado, presente e futuro
        } else {  
          datas.final[indexEl].value += el.value;
        }

        // console.log( el.label, el.value );
        // datas.final[index]['label'] = el.label;
        // datas.final[index]['value'] = el.value;
      });
    });

    // FINAL FINAL FINAL

    // Juros e custos pagos com atrasdo
    // nao é repassado ao coordenador
    // a diferença do valor pago menos o valor esperado. 
    // Ex: 1355,23 (com jutos) - 1350,00 (sem juros) = 5,23
    // const jurosCustos = values.current.sumValueInPay - values.current.sumValueInPayWork; 
    // const restValue = datas.final[datas.final.length-1].value; 

    // Nessa etapa estamos adicionando ao cec o valor dos custos:
    // datas.current.coordinatorDiscount

    datas.finalCosts = [];
    datas.final.forEach( element => {
      const {label,value} = element;
      let v;
      
      // cordenador
      if( String(label).indexOf('Coord') > -1 ){ 
        v = { label: 'Repasse Coord - Despesas', value: value - datas.current.coordinatorDiscount, };
      // cec
      } else if( String(label).indexOf('CEC') > -1 ){ 
        v = { label: 'Repasse CEC + Despesas', value: value + datas.current.coordinatorDiscount, };
      } else {
        v = { label: label, value: value, };
      }

      // datas.current.coordinatorDiscount
      datas.finalCosts.push(v);
    });

    //console.log(datas.final);
    // ---------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------

    setValues(datas);

  }

  let sumTotalNoPay = 0;
  const reportNoPay = () => {

    let query = " ";
    query = ""
    + " SELECT f.MIX, f.ID, f.VALUE_IN, f.VALUE_IN_PAY, f.NUMBER, f.NUMBER_FINAL_PAY, "
    + " f.DATE_END, f.DATE_FINAL, f.DATE_CANCELED, f.PAY_TYPE, p.NAME as COURSE_NAME, c.NAME as CLIENT_NAME, "
    + " MONTH(f.DATE_END) as MONTH, MONTH(f.DATE_FINAL) as MONTH_PAY, "
    + " (CASE WHEN INSTR(f.DESCRIPTION, 'ACORDO') > 0 THEN 1 ELSE 0 END) as ACORDO, "
    + " SUBSTR(f.DESCRIPTION,0,10) as PARC, "
    + " f.DESCRIPTION "
    + " FROM "
    + " dbd_financial as f "
    + " LEFT JOIN dbd_project as p on (p.ID = f.ID_PROJECT) "
    + " LEFT JOIN dbd_client as c on (c.ID = f.ID_CLIENT) "
    + " WHERE  "
    + ` f.ID_PROJECT = ${idCourse} `
    + " AND LEFT(f.DATE_CANCELED,4) like LEFT('0000-',4) "
    + " AND f.MIX <> 'FINANCIAL_NOTE' "
    + " AND f.MIX <> 'FINANCIAL_TRANS' "
    + " AND f.IS_TRANS = 0 "
    + ` AND f.DATE_END < ${future} `
    + " AND LEFT(f.DATE_FINAL,4) like LEFT('0000-',4) "
    //+ " GROUP by f.ID_CLIENT,MONTH(f.DATE_FINAL) "
    + " ORDER by CLIENT_NAME, f.DATE_END ";

    // window.alert( query );
    console.log('SQLnoPay', query);

    const data = { query: query }
    new ApiServer().post('money/report/report-coordinator-payment','month-period', data ).then( el => {
      if(el.statusText === 'OK'){
        console.log( el.data );
        setListFinancialNoPay( el.data );
      }
    }).catch( err => setListFinancialNoPay([]) );
  }

  const getColorRowPay = (el) => {
    if(el.isPast === 1){
      return '#fff19a';
    }
    if(el.isFuture === 1){
      return '#5ba4cf';
    }
    if(el.MONTH_PAY === 0){
      return '#ffc5c5';
    }
    if(el.MONTH_PAY > 0){
      return '#9AFF9A';
    } 
  }

  const getLabelRowPay = (el) => {
    if(el.NUMBER_FINAL_PAY === '*'){
      return {label: 'PG.NO FUT.', nick: 'FU'};
    }
    if(el.isPast === 1){
      return {label: 'PG.C.ATRASO', nick: 'AT'};
    }
    if(el.isFuture === 1){
      return {label: 'PG.ANTECIP.', nick: 'AN'};
    }
    if(el.MONTH_PAY === 0){
      return {label: '', nick: 'NP'};
    }
    if(el.MONTH_PAY > 0){
      return {label: 'PG.EM DIA', nick: 'ED'};
    } 
    return {label: '', nick: ''};
  }

  const [partsItem, setPartsItem] = useState({
    label: '',
    valuePercent: 0,
    valueFixed: 0,
  });

  const partsValuePercent = ({target}) => {

    let value = String(target.value).trim().replaceAll(',','.').replaceAll(' ','').replaceAll('%','');
    console.log(value);
    value = value > 1 ? Number(value) / 100 : Number(value);
    value = value > 100 ? 0 : ( value > 1 ? 0 : value ) ;
    console.log(value);

    setPartsItem({
      ...partsItem,
      valuePercent: value,
    });
  }

  const partsAdd = () => {
    if( !partsItem.valuePercent ){
      return window.alert('Insira uma porcentagem numérica válida. ');
    }
    setPercentsAndDiscounts({
      ...percentsAndDiscounts,
      getParts: [...percentsAndDiscounts.getParts, partsItem],
    });
  }

  const partsRemoveAll = () => {
    setPercentsAndDiscounts({
      ...percentsAndDiscounts,
      getParts: []
    });
  }

  const [discountsItem, setDiscountsItem] = useState({
    label: '',
    valuePercent: 0,
    valueFixed: 0,
  });

  const discountsValueFixed = ({target}) => {
    let value = Number(String(target.value).trim().replaceAll(',','.').replaceAll(' ','').replaceAll('%',''));
    setDiscountsItem({
      ...discountsItem,
      valueFixed: value,
    });
  }

  const discountsAdd = () => {
    if( !discountsItem.valueFixed ){
      return window.alert('Insira um valor numérico válido. ');
    }
    setPercentsAndDiscounts({
      ...percentsAndDiscounts,
      discountPaymentCoordinator: [...percentsAndDiscounts.discountPaymentCoordinator, discountsItem],
    });
  }

  const discountsRemoveAll = () => {
    setPercentsAndDiscounts({
      ...percentsAndDiscounts,
      discountPaymentCoordinator: []
    });
  }

  //new ApiServer().post( server, 'reports', form ).then( data => {

  const loadData = () => {

    if( idCourse === 0 ){
      return window.alert('Selecione uma turma');
    }

    new ApiServer().get('/money/report/report-coordinator-payment/coordinator-payment-report-json', idCourse, {}).then( data => {
      //window.alert(JSON.stringify(data.data));
      console.log( data.data );
      if( data.statusText === 'OK'){
        setPercentsAndDiscounts( data.data );
      }
    });
    
  }

  const saveData = () => {

    if( idCourse === 0 ){
      return window.alert('Selecione uma turma');
    }

    //  + "\n\n" + JSON.stringify(percentsAndDiscounts)
    const conf = window.confirm('Deseja substituir os antigos dados registrados pelos novos dados?')
    if( conf ) {
      console.log( JSON.stringify(percentsAndDiscounts) );
      const form = {
        id_course: idCourse,
        json: JSON.stringify(percentsAndDiscounts),
      };
      new ApiServer().post('/money/report/report-coordinator-payment/coordinator-payment-report-json', '', form).then( data => {
        if( data.statusText === 'OK' ){
          window.alert('Salvo!');
        } else {
          window.alert('Houve algum problema');
        }
      });
      }
  }

  const sendToServer = (courseNameString) => {

    // se nao houver lançamentos então pega o nome do curso com os nao pagos
    if( !courseNameString && courseName ){
      courseNameString = courseName;
    } else if( !courseNameString && listFinancialNoPay.length > 0 ) {
        courseNameString = listFinancialNoPay[0].COURSE_NAME;
        setCourseName(courseNameString); 
    }
    
    ////-------------------------------------------- end new
    const tables = [];

    const table0 = tables2json.one('table0');
    if(table0){
      table0.title = "Lançamentos - Mês Atual";
      tables.push(table0);
      console.log(table0);
    }

    // ---------------- Faturado no Mês Atual

    const table1 = tables2json.one('table1');
    if(table1){
      table1.title = "Faturado no Mês Atual";
      tables.push(table1);
    }

    const table3 = tables2json.one('table3');
    tables.push(table3);

    // ---------------- Faturas em Atraso Recebidas no Mês

    const table4 = tables2json.one('table4');
    table4.title = "Faturas em Atraso Recebidas no Mês";
    tables.push(table4);  

    const table5 = tables2json.one('table5');
    tables.push(table5);

    // ---------------- Adiantamentos Recebidos no Mês

    const table6 = tables2json.one('table6');
    table6.title = "Adiantamentos Recebidos no Mês";
    tables.push(table6);
    
    const table7 = tables2json.one('table7');
    tables.push(table7);

    // ---------------- Situação de Inadimplência

    const tables_inadimplentes = [];

    const table8 = tables2json.one('table8');
    if( table8 ){
      table8.title = "Situação de Inadimplência";
      //table8.options = { addPage: "true", };
      tables_inadimplentes.push(table8);  
    }

    const table81 = tables2json.one('table81');
    if( table81 ){
      tables_inadimplentes.push(table81);  
    }

    // ---------------- Resumo Final

    const table9 = tables2json.one('table9');
    table9.title = "Soma";
    tables.push(table9);

    const table2 = tables2json.one('table2');
    if(table2){
      table2.subtitle = "Despesas e Custos";
      tables.push(table2);  
    }

    const tableRF = tables2json.one('tableRepasseFinal');
    tableRF.title = "Repasse Final";
    tables.push(tableRF);

    

    console.log(tables);
    
    const form = {
      file_name: `document-report-course-${idCourse}-between-${past.replaceAll("'",'').trim()}-and-${future.replaceAll("'",'').trim()}.pdf`,
      id_course: idCourse,
      date_future: future,
      date_past: past,
      tables: tables,
      tables_inadimplentes: tables_inadimplentes,
      title: courseNameString,
      subtitle: `Período ${past.replaceAll("'",'')} até ${future.replaceAll("'",'')}`,
    }

    // full link
    // http://daybyday-server.gnnc.com.br/api/storage/reports
    // http://localhost:8088/api/storage/reports
    
    const link = 'http://daybyday-server.gnnc.com.br/api/download/' + form.file_name;
    // const link = 'http://localhost:3088/api/download/' + form.file_name;

    const server = 'storage';
    // const server = 'http://localhost:3088/api/storage';

    setDownloadFPDFileLink(link);

    console.log('address', link, server);
    
    new ApiServer().post( server, 'reports', form ).then( data => {
      console.log('all',data);
      if( data.statusText === 'OK'){
        console.log( data );
      }else{
        console.log( data );
      }
    }).catch( err => console.log( err ) );
    ////-------------------------------------------- end new

    // const doc = new jsPDF();

    // doc.setFontSize(12);
    // doc.text(courseName, 15, 10);
    // doc.setFontSize(9);
    // doc.text(`Período ${past.replaceAll("'",'')} até ${future.replaceAll("'",'')}`, 15, 13);
    // doc.setFontSize(7);

    // // var t = ['#table0','#table1','#table1','#table1','#table1','#table1'];
    // for(var i = 0; i < 10; i++ ){

    //   autoTable(doc, {
    //     html: '#table'+i,
    //     tableWidth: 'wrap',
    //     styles: { cellPadding: 1, fontSize: 7 },
    //   })
  
    // }
    // doc.save('table.pdf');

    /*
    TASKS TODO
    - [OK] o limite das datas está pelo vencimento, mas deve considerar o pagamento.
    - [OK] indicar quando tiver na descrição ACORDO.
    - [OK] porcentagem com 5 digitos = 48.88
    - [OK] Restante > Repasse CEC 
    - [OK] add os títulos
    - porcentagem de pagamento fazer por alunos que pagaram, nao por valor previsto e pago
    - colocar a porcentagem fix
    - colocar a porcentagem explicita
    - quando o aluno pague em dia o valor esperado já apresente desconto e não total. Por exemplo, 1435 - 10% de desconto pelo pagamento em dia. Então o esperado é 1435 - 143,5
    - [OK] No formulário Respesas add Matrícula
    - porcentagem de desconto para cada aluno. em R$ ou %. para registrar no relatório
    - possibilidade de criar uma acrescimo para o coordenador, ao invès de retirar dá uma ajuda de custo.
    - talvez enviar como json string file para addPage em columnsSize funcionar.

    - Curso
    - valor do curso / mensalidade
    - desconto antecipação
    - lista de despesas json
    - lista de repasses json

    - Reajuste anual 5%
    - repassar o juros pro coordenador (nao é certeza, talvez um botão no formulario)
    */

  }

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Content style={styles.content}>

      <div style={{padding:10, right: 10, position:'absolute',display: (!showReport ? 'none' : 'block')}}>
        <a href="javascript:;" onClick={()=>setShowReport(false)}>
          <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
        </a>
      </div>
      <Title level={4}>Repasse Coordenador & Resumo Turma</Title>

      {/* <div style={{padding:20, backgroundColor:'#ffffff', border: '1px solid #dddddd', borderRadius: 10, display: (showReport ? 'none' : 'block')}}> */}
      <div style={{display: (showReport ? 'none' : 'block')}}>
      <div style={{fontWeight:700,marginTop:10}}>Turma</div>
        {/* <Select defaultValue="0" marginTop:10le={{ width: 500 }} onChange={onChangeIdCourseSelect}>
          {listCourses.map( el => (
            <Option value={el.ID} key={el.ID}>{el.NAME}</Option>
          ))}
        </Select> */}
        <Select
          showSearch
          style={{ width: 500 }}
          placeholder="Select a person"
          optionFilterProp="children"
          onChange={onChangeIdCourseSelect}
          // onFocus={onChangeIdCourseSelect}
          // onBlur={onChangeIdCourseSelect}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {listCourses.map( el => (
            <Option value={el.ID} key={el.ID}>{el.NAME}</Option>
          ))}
        </Select>

        <InputNumber min={1} max={999} defaultValue={idCourse} value={idCourse} onChange={onChangeIdCourse} />

        {/* <DatePicker onChange={onChangeDate} picker="month" /> */}
        <div style={{fontWeight:700,marginTop:10}}>Período/Mês</div>
        {/* <DatePicker onChange={onChangeDate} picker="month" /> */}
        <RangePicker onChange={onChangeDate} style={{ width: 300 }} />

        <div style={{fontWeight:700,marginTop:10}}>Repartição %</div>

        <div>
          <Select onChange={(value)=>setPartsItem({...partsItem,label:value})} style={{ width: 300 }} >
            {['Repasse ACO','Repasse UECE','Repasse UFC','Repasse CFO','Repasse Coordenador'].map( el => (
              <Option value={el} key={el}>{el}</Option>
            ))}
          </Select>
          <Input onChange={partsValuePercent} id="partsPercent" placeholder="%" style={{ width: 100 }} maxLength={5} />
          <Button onClick={partsAdd}>Adicionar </Button>
          <Button onClick={partsRemoveAll}>Limpar lista </Button>
        </div>

        <ul style={{ padding:0, paddingLeft:20, margin:0, marginTop: 10 }}>
          {percentsAndDiscounts.getParts.map( el => (
            <li style={{ fontSize:12 }}>{el.label} | {el.valuePercent * 100} %</li>
          ))}
        </ul>

        <div style={{fontWeight:700,marginTop:10}}>Despesas</div>
        <div>
          <Select onChange={(value)=>setDiscountsItem({...discountsItem,label:value})} style={{ width: 300 }} >
            {['Domingo','Matrícula','Hotel','Adiantamento','Passagens','Transporte','Material','Papelaria','Outros'].map( el => (
              <Option value={el} key={el}>{el}</Option>
            ))}
          </Select>
          <Input onChange={discountsValueFixed}  placeholder="R$" style={{ width: 100 }} maxLength={10} />
          <Button onClick={discountsAdd}>Adicionar </Button>
          <Button onClick={discountsRemoveAll}>Limpar lista </Button>
        </div>

        <ul style={{ padding:0, paddingLeft:20, margin:0, marginTop: 10 }}>
          {percentsAndDiscounts.discountPaymentCoordinator.map( el => (
            <li style={{ fontSize:12 }}>{el.label} | {current(el.valueFixed)} </li>
          ))}
        </ul>

        <div style={{fontWeight:700,marginTop:10}}>Dados cadastrados acima</div>
        <div>
          <Button onClick={loadData}>Carregar</Button>
          <Button onClick={saveData}>Salvar</Button>
        </div>
        <div style={{fontWeight:700,marginTop:10}}>Iniciar</div>
        <div>
          <Button onClick={reportMonth} type="primary" size="large" style={{backgroundColor:'#444444', borderColor:'#555555'}} >Gerar Relatório</Button>
        </div>
      </div>

      {showReport && (
        <React.Fragment>

          {/* <Button type="primary" size="large" onClick={sendToServer} >PDF File</Button> */}

          {downloadFPDFile?(
            <a href={downloadFPDFileLink} target="_blank" rel="noreferrer" >
              <Button type="primary" size="large">Download</Button>
            </a>
          ):(<Button type="primary" size="large">Aguarde... gerando arquivo PDF</Button>)}

          <Alert
            // message="Nota importante"
            description={<div>
              Clique em <strong>Download</strong> para baixar o relatório. Para <strong>voltar a pesquisa</strong> clique em <a href="javascript:;"  onClick={()=>setShowReport(false)}>fechar</a> (x).
            </div>}
            type="info"
            showIcon
          />

          <h2 style={styles.h2}>Período <small>{past.replaceAll("'",'')} até {future.replaceAll("'",'')}</small></h2>      
          <h3>{courseName} {idCourse}</h3>
          <code>  
            {sql}
          </code>
          <table width="100%" style={styles.table} id="table0">
            <thead>
              <tr>
                {/* <th style={styles.itemHead}> Id </th> */}
                <th style={styles.itemHead}> N Lanç </th>
                <th style={styles.itemHead}> N Pag </th>
                <th style={styles.itemHead}> Nome </th>
                <th style={styles.itemHead}> Valor </th>
                <th style={styles.itemHead}> Pago </th>
                <th style={styles.itemHead}> Parcela </th>
                <th style={styles.itemHead}> Vencimento </th>
                <th style={styles.itemHead}> Pagamento </th>
                <th style={styles.itemHead}> Venc </th>
                <th style={styles.itemHead}> Pag. </th>
                <th style={styles.itemHead}> Status </th>
                <th style={styles.itemHead}> ST </th>
              </tr>
            </thead>
            <tbody>
              {listFinancial.map( (el,index) =>{

                {/* SUM_VALUE_IN += el.VALUE_IN;
                SUM_VALUE_IN_PAY += el.VALUE_IN_PAY;
                SUM_VALUE_IN_NOT_PAY = SUM_VALUE_IN-SUM_VALUE_IN_PAY;
                SUM_VALUE_IN_PAY_PERCENT = (SUM_VALUE_IN_PAY / SUM_VALUE_IN * 100);

                VALUE_PERCENT_1 = Number( SUM_VALUE_IN_PAY * percentsAndDiscounts.getParts[0].valuePercent );
                VALUE_PERCENT_2 = Number( SUM_VALUE_IN_PAY - (SUM_VALUE_IN_PAY * percentsAndDiscounts.getParts[0].valuePercent) ) * percentsAndDiscounts.getParts[1].valuePercent ;

                SUM_VALUE_IN_PAY_REST = SUM_VALUE_IN_PAY - VALUE_PERCENT_1 - VALUE_PERCENT_2; */}

                return (
                  <React.Fragment>

                    <tr style={{backgroundColor: getColorRowPay(el)}} key={index}>
                      {/* <td style={styles.item}> {el.ROWS > 1 ? `(${el.ROWS})` : `:${el.ID}`} </td> */}
                      {/* <td style={styles.item}> {el.isPast===0 && el.isFuture===0 ? index+1 : ''} </td> */}
                      <td style={styles.item}> {el.NUMBER} </td>
                      <td style={styles.item}> {el.NUMBER_FINAL_PAY} </td>
                      <td style={styles.itemName}> {el.CLIENT_NAME} </td>
                      <td style={styles.item}> {current(el.VALUE_IN)} </td>
                      <td style={styles.itemValuePay}>{current(el.VALUE_IN_PAY)}</td>
                      <td style={styles.item}> {getParcel(el)} </td>
                      {/* <td style={styles.item}> {String(el.DATE_END).substr(0,10)} </td>
                      <td style={styles.item}> {String(el.DATE_FINAL).substr(0,10)} </td> */}
                      <td style={styles.item}> {dateToPtBr(el.DATE_END)} </td>
                      <td style={styles.item}> {dateToPtBr(el.DATE_FINAL)} </td>
                      <td style={styles.item}> {month[el.MONTH-1]} </td>
                      <td style={styles.item}> {month[el.MONTH_PAY-1]} </td>
                      <td style={styles.item}> {getLabelRowPay(el).label} </td>
                      <td style={styles.item}> {getLabelRowPay(el).nick} </td>
                    </tr>
                  </React.Fragment>
                )

              })}
            </tbody>
          </table>

          <h2 style={styles.h2}>Faturado no Mês</h2>

          <table style={styles.tableResume} width="100%" id="table1">
            <tr style={styles.rowTitle}>
              <td width="16.5%" style={styles.itemColumn}>Esperado</td>
              <td width="16.5%" style={styles.itemColumn}>Faturado</td>
              <td width="16.5%" style={styles.itemColumn}>Custos: Juros/Multa ¹</td>
              <td width="16.5%" style={styles.itemColumn}>Não faturado</td>
              <td width="16.5%" style={{...styles.itemColumn,backgroundColor:'#9AFF9A'}}>% faturado</td>
              <td width="16.5%" style={{...styles.itemColumn,backgroundColor:'#ffc5c5'}}>% não faturado</td>
            </tr>
            <tr>
              <td style={styles.itemColumnImportant}>{current(values.current.sumValueIn)} </td>
              <td style={styles.itemColumnImportant}>{current(values.current.sumValueInPayWork)}</td>
              <td style={styles.itemColumnImportant}>{current(values.current.sumValueInPay-values.current.sumValueInPayWork)}</td>
              <td style={styles.itemColumnImportant}>{current(values.current.sumValueInNotPay)}</td>
              <td style={{...styles.itemColumnImportant, fontWeight:700, textAlign:'right', color: 'green'}}>{Number(values.current.sumValueInPayPercent).toFixed(1) }%</td>
              <td style={{...styles.itemColumnImportant, textAlign:'right',}}>{Number(values.current.sumValueInNoPayPercent).toFixed(1) }%</td>
            </tr>
          </table>

          <table style={styles.tableResume} width="100%" id="table3">
            <tr style={styles.rowTitle}>
              {values.current.partsResume.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr style={{fontSize:18,}}>
              {values.current.partsResume.map( el => (
                <td style={styles.itemColumn}>{current(el.value, true)}</td>
              ))}
            </tr>
          </table>

          {/* <table style={styles.tableResume} width="100%">
            <tr style={styles.rowTitle}>
              {values.current.partsResume2.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr style={{fontSize:18,}}>
              {values.current.partsResume2.map( el => (
                <td style={styles.itemColumn}>{Number(el.value).toFixed(2)}</td>
              ))}
            </tr>
          </table> */}

          <h2 style={styles.h2}>Faturas em Atraso Recebidas no Mês</h2>

          <table style={styles.tableResume} width="100%" id="table4">
            <tr style={styles.rowTitle}>
              <td width="33%" style={styles.itemColumn}>Esperado / Repasse</td>
              <td width="33%" style={styles.itemColumn}>Faturado + Juros/Multa</td>
              <td width="33%" style={styles.itemColumn}>Juros/Multa</td>
            </tr>
            <tr>
              {/* <td style={styles.itemColumn}>{current(values.past.sumValueIn)} </td>
              <td style={styles.itemColumn}>{current(values.past.sumValueInPay)}</td>
              <td style={styles.itemColumn}>{current(values.past.sumValueInNotPay, true)}</td> */}

              <td style={styles.itemColumn}>{current(values.past.sumValueInPayWork)} </td>
              <td style={styles.itemColumn}>{current(values.past.sumValueInPay)}</td>
              <td style={styles.itemColumn}>{current(values.past.sumValueInPay - values.past.sumValueInPayWork, true)}</td>
            </tr>
          </table>

          <table style={styles.tableResume} width="100%" id="table5">
            <tr style={styles.rowTitle}>
              {values.past.partsResume.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr style={{fontSize:18,}}>
              {values.past.partsResume.map( el => (
                <td style={styles.itemColumn}>{current(el.value, true)}</td>
              ))}
            </tr>
          </table>

          <h2 style={styles.h2}>Adiantamentos Recebidos no Mês</h2>

          <table style={styles.tableResume} width="100%" id="table6">
            <tr style={styles.rowTitle}>
              <td width="100%" style={styles.itemColumn}>Esperado / Repasse</td>
            </tr>
            <tr>
              <td style={styles.itemColumn}>{current(values.future.sumValueIn, true)} </td>
            </tr>
          </table>

          <table style={styles.tableResume} width="100%" id="table7">
            <tr style={styles.rowTitle}>
              {values.future.partsResume.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr style={{fontSize:18,}}>
              {values.future.partsResume.map( el => (
                <td style={styles.itemColumn}>{current(el.value, true)}</td>
              ))}
            </tr>
          </table>

          <h2 style={styles.h2}>Despesas e Custos</h2>

          {percentsAndDiscounts.discountPaymentCoordinator.length ? (<span style={{color:'#888888', fontSize: 15}}>Despesas Previstas</span>) : ''}

          <table style={styles.tableResume} width="100%" id="table2">
            <tr style={styles.rowTitle}>
              {percentsAndDiscounts.discountPaymentCoordinator.map( el => (
                <td width="12.5%" style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr style={{fontSize:18,}}>
              {percentsAndDiscounts.discountPaymentCoordinator.map( el => (
                <td style={styles.itemColumn}>{el.valueFixed ? (<>{current(el.valueFixed)}</>) : null }</td>
              ))}
            </tr>
          </table>

          <h2 style={styles.h2}>Soma</h2>

          <table style={styles.tableResume} width="100%" id="table9">
            <tr style={styles.rowTitleFinal}>
              {values.final.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr>
              {values.final.map( el => (
                <td style={styles.itemColumnFinal}>{current(el.value)}</td>
              ))}
            </tr>
          </table>

          <h2 style={styles.h2}>Repasses Final</h2>

          <table style={styles.tableResume} width="100%" id="tableRepasseFinal">
            <tr style={styles.rowTitleFinal}>
              {values.finalCosts.map( el => (
                <td style={styles.itemColumn}>{el.label}</td>
              ))}
            </tr>
            <tr>
              {values.finalCosts.map( el => (
                <td style={styles.itemColumnFinal}>{current(el.value)}</td>
              ))}
            </tr>
          </table>

          <h2 style={styles.h2}>Situação de Inadimplência</h2>

          <table width="100%" style={styles.table} id="table8">
            <thead>
              <tr>
                {/* <th style={styles.itemHead}> Id </th> */}
                <th style={styles.itemHead}> Lançamento </th>
                <th style={styles.itemHead}> Nome </th>
                <th style={styles.itemHead}> Vencimento </th>
                <th style={styles.itemHead}> Venc. </th>
                <th style={styles.itemHead}> Valor </th>
                <th style={styles.itemHead}> Parcela </th>
              </tr>
            </thead>
            <tbody>
              {listFinancialNoPay.map( (el,index) =>{
                sumTotalNoPay += Number(el.VALUE_IN);
                return (
                  <React.Fragment>
                    <tr style={{backgroundColor: getColorRowPay(el)}} key={index}>
                      {/* <td style={styles.item}> {el.ROWS > 1 ? `(${el.ROWS})` : `:${el.ID}`} </td> */}
                      {/* <td style={styles.item}> {el.isPast===0 && el.isFuture===0 ? index+1 : ''} </td> */}
                      <td style={styles.item}> {el.NUMBER} </td>
                      <td style={styles.itemName}> {el.CLIENT_NAME} </td>
                      {/* <td style={styles.item}> {String(el.DATE_END).substr(0,10)} </td> */}
                      <td style={styles.item}> {dateToPtBr(el.DATE_END)} </td>
                      <td style={styles.item}> {month[el.MONTH-1]} </td>
                      <td style={styles.item}> {current(el.VALUE_IN)} </td>
                      <td style={styles.item}> {getParcel(el)} </td>
                    </tr>
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>

          <table width="100%" style={styles.table} id="table81">
            <thead>
              <tr>
                <th style={styles.itemHead}> Valor Esperado: </th>
                <th style={styles.itemHead}> - </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={styles.itemColumnFinal}> {current(sumTotalNoPay)} </td>
                <td style={styles.itemColumnFinal}> - </td>
              </tr>
            </tbody>
          </table>


          {/* <Button type="primary" size="large" onClick={sendToServer} >PDF File</Button> */}

          {downloadFPDFile?(
            <a href={downloadFPDFileLink} target="_blank" rel="noreferrer" >
              <Button type="primary" size="large">Download</Button>
            </a>
          ):(<Button type="primary" size="large">Aguarde... gerando arquivo PDF</Button>)}


        </React.Fragment>
      )}

      <p style={styles.cache}>
        {new Date().getTime()} {0}
      </p>

    </Content>
  );
}

const styles = {
  content: {
    padding: "15px 25px",
  },
  table: {
    fontSize: 10,
  },
  nameCourse: {
    fontSize: 22,
    padding: '20px 5px',
  },
  item: {
    padding: 7,
    borderBottom: '1px solid #777777',
  },
  itemName: {
    padding: 7,
    fontWeight: 700,
    fontSize: 12,
    borderBottom: '1px solid #777777',
  },
  itemValuePay: {
    padding: 7,
    fontWeight: 700,
    fontSize: 12,
    borderBottom: '1px solid #777777',
  },
  itemHead: {
    fontSize: 12,
    textAlign: 'left',
    padding: 7,
  },
  h2: {
    margin: '15px 0 10px 0',
    fontWeight: 700,
    fontSize: 16,
  },
  tableResume: {
    width: '100%',
    margin: '5px 0',
  },
  itemColumn: {
    padding: '3px 4px',
    fontSize: 13,
  },
  itemColumnImportant: {
    padding: '3px 4px',
    fontSize: 20,
  },
  rowTitle: {
    fontSize: 12,
    fontWeight: 700,
    backgroundColor:'#dddddd',
    border: '1px solid #777777',
  },
  rowTitleFinal: {
    fontSize: 12,
    fontWeight: 700,
    backgroundColor:'#aaaaaa',
    border: '1px solid #777777',
  },
  itemColumnFinal: {
    padding: '3px 4px',
    fontSize: 16,
  },
  cache: {
    marginTop: 10,
    fontSize: 9,
    color: 'grey',
  },
};
