import { Button, InputNumber, Select, Alert, Checkbox } from "antd";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React, {useState, useEffect} from "react";
import ApiServer from "../../../resources/conn/ApiServer";

export default function ReportUECE() {

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);

  const [yearGen, setYearGen] = useState([]);
  const [thisYear] = useState(new Date().getFullYear());
  const [thisMonth] = useState(new Date().getMonth()+1);

  const { Option } = Select;
  const [idCourse, setIdCourse] = useState(0);//` '2021-04-21' `;
  const [listCourses, setListCourses] = useState([]);
  const [courseName, setCourseName] = useState('');
  const [getAllCourses, setAllCourses] = useState(true);

  // http://daybyday-server.gnnc.com.br/api/courses/repasses-uece/all/2021/07
  // http://daybyday-server.gnnc.com.br/api/courses/repasses-uece/pay/2021/07
  // http://daybyday-server.gnnc.com.br/api/courses/repasses-uece/no-pay/2021/07

  const monthFull = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho", "Agosto","Setembro","Outubro","Novembro","Dezembro"];
  // const monthShort = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul","Ago", "Set", "Out", "Nov", "Dez"];

  const urlYearMonth = (name, y, m, idc) => {
    return `${process.env.REACT_APP_API_HOST}courses/repasses-uece/${name}/${y}/${m}/${idc}`;
  }

  const openReport = (name) => {
    window.open(urlYearMonth(name, year, month, idCourse), '_blank');
  }

  function onChangeCheckSelectCourse(e) {
    setAllCourses(e.target.checked);
    e.target.checked && setIdCourse(0);
  }

  const getCourses = () => {
    const query = `select c.ID, c.NAME from dbd_project as c where c.ID_CLIENT = 1847 AND c.NAME like "%UECE%" order by c.NAME limit 0,500`;
    const data = { query: query }
    new ApiServer().post('money/report/report-coordinator-payment','month-period', data ).then( el => {

      if(el.statusText === 'OK'){
        // console.log(el.data);
        setListCourses(el.data)
      }

    }).catch( err => setListCourses([]) );
    
  }

  function onChangeIdCourse(value) {
    setIdCourse(value);
  }
  
  function onChangeIdCourseSelect( value, event ) {
    setIdCourse(value);
    setCourseName(event.children);
  }

  useEffect(() => {
    let y = [];
    const fy = thisYear + 1;
    for(let i = fy; i > fy - 10; i-- ){
      y.push(i);
    }
    setYearGen(y);
    setYear(thisYear);
    setMonth(thisMonth);
    getCourses();

  }, [])

  return (
    <Content style={styles.content}>
      <Title level={4}>Repasse UECE</Title>

      {/* <Alert
        message="Nota importante"
        description="Abrirá em outra página para fazer download."
        type="info"
        showIcon
      /> */}

      <Content style={{paddingBottom:15}}>

      <div style={{fontWeight:700,marginTop:10}}>Turma</div>

      <div style={{display:'block', paddingTop: 5 }}>

        <Checkbox onChange={onChangeCheckSelectCourse} checked={getAllCourses}>Todas as turmas</Checkbox>

      </div>

      {getAllCourses || (
        <div style={{paddingTop: 15}}>
          <Select
            showSearch
            style={{ width: 500 }}
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={onChangeIdCourseSelect}
            // onFocus={onChangeIdCourseSelect}
            // onBlur={onChangeIdCourseSelect}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listCourses.map( el => (
              <Option value={el.ID} key={el.ID}>{el.NAME}</Option>
            ))}
          </Select>
          <InputNumber min={1} max={999} defaultValue={idCourse} value={idCourse} onChange={onChangeIdCourse} />
        </div>
        )}

        <div style={{fontWeight:700,marginTop:10}}>Ano e Mês</div>
        <div>
          <Select onChange={(value)=>setYear(value)} style={{ width: 200 }} value={year} >
            {yearGen.map( (el) => (
              <Option value={el} key={el}>{el}</Option>
            ))}
          </Select>
          <Select onChange={(value)=>setMonth(value)} style={{ width: 200 }} value={month} >
            {monthFull.map( (el, index) => (
              <Option value={index+1} key={el}>({index+1}) {el}</Option>
            ))}
          </Select>
        </div>

      </Content>

      <Button type="primary" size="large" style={{backgroundColor:'#444444', borderColor:'#555555'}} onClick={()=>openReport('all')}>Gerar relatório</Button>
      <Button type="primary" size="large" style={{backgroundColor:'green', borderColor:'#555555'}} onClick={()=>openReport('pay')}>Faturado</Button>
      <Button type="primary" size="large" style={{backgroundColor:'red', borderColor:'#555555'}} onClick={()=>openReport('no-pay')}>Débito</Button>

      <p style={styles.cache}>
        {new Date().getTime()} {0}
      </p>
      
    </Content>
  );
}

const styles = {
  content: {
    padding: "15px 25px",
  },
  cache: {
    marginTop: 10,
    fontSize: 9,
    color: 'grey',
  },
};