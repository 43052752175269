import { Button, Select, Alert } from "antd";
import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React, {useState, useEffect} from "react";

export default function ReportDebit() {

  const [year, setYear] = useState(new Date().getFullYear());

  const [yearGen, setYearGen] = useState([]);
  const [thisYear] = useState(new Date().getFullYear());

  const { Option } = Select;

  const urlYearMonth = (name, y) => {
    return `${process.env.REACT_APP_API_HOST}courses/debit/${name}/${y}`;
  }

  const openReport = (name) => {
    window.open(urlYearMonth(name, year), '_blank');
  }

  useEffect(() => {
    let y = [];
    const fy = thisYear + 1;
    for(let i = fy; i > fy - 10; i-- ){
      y.push(i);
    }
    setYearGen(y);
    setYear(thisYear);
  }, [])

  return (
    <Content style={styles.content}>
      <Title level={4}>Inadimplência</Title>

      {/* <Alert
        message="Nota importante"
        description="Abrirá em outra página para fazer download."
        type="info"
        showIcon
      /> */}

      <Content style={{paddingBottom:15}}>

        <div style={{fontWeight:700,marginTop:10}}>Ano e Mês</div>
        <div>
          <Select onChange={(value)=>setYear(value)} style={{ width: 200 }} value={year} >
            {yearGen.map( (el) => (
              <Option value={el} key={el}>{el}</Option>
            ))}
          </Select>
        </div>

      </Content>

      <Button type="primary" size="large" style={{backgroundColor:'#444444', borderColor:'#555555'}} onClick={()=>openReport('in')}>Gerar relatório</Button>

      <p style={styles.cache}>
        {new Date().getTime()} {0}
      </p>
  
    </Content>
  );
}

const styles = {
  content: {
    padding: "15px 25px",
  },
  cache: {
    marginTop: 10,
    fontSize: 9,
    color: 'grey',
  },
};