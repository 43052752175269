import { Button, Input, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

// const urlServer = 'http://localhost:3012/api';
const urlServer = 'http://daybyday-server.gnnc.com.br/api/';

export default function ListStudentsPaymentRecovery() {

  const [wait, setWait] = React.useState(true);
  const [listFinancial, setListFinancial] = React.useState([]);
  const [emailTest, setEmailTest] = React.useState('coordenacao@aco.org.br'); //natancabral@hotmail.com, coordenacao@aco.org.br
  const { Option } = Select;

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth()+1);

  const [yearGen, setYearGen] = useState([]);
  const [thisYear] = useState(new Date().getFullYear());
  const [thisMonth] = useState(new Date().getMonth()+1);

  const monthFull = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho", "Agosto","Setembro","Outubro","Novembro","Dezembro"];

  let courseName = '';

  async function listStudents(y, m) {

    m = String(m).padStart(2, '0')

    try {

      const url = `http://daybyday-server.gnnc.com.br/api/payment/recovery/list-students/${y}/${m}`;
      const data = await axios.get(url);
      const content = data.data;
      setListFinancial(content);
  
    } catch (error) {
      
      console.log(error);
      setListFinancial([]);

    }

    setWait(false);

  }

  // const columns = [
  //   {
  //     title: 'Id',
  //     dataIndex: 'ID',
  //     key: 'ID',
  //     render: text => <a>{text}</a>,
  //   },
  //   {
  //     title: 'Name',
  //     dataIndex: 'NAME',
  //     key: 'NAME',
  //   },
  //   {
  //     title: 'Turma',
  //     dataIndex: 'NAME_COURSE',
  //     key: 'NAME_COURSE',
  //   },
  //   {
  //     title: 'Email',
  //     dataIndex: 'EMAIL',
  //     key: 'EMAIL',
  //     render: text => <a>{text}</a>,
  //   },
  // ];

  async function sendMailTesting(index, datas) {

    const item = document.getElementById(`item${index}`);
    item.style.opacity = 0.2;

    const { ID_CLIENT, NAME, EMAIL, EMAIL_FINANCIAL, VALUE_FINAL, NAME_COURSE, DATES_END, IDS } = datas || {};

    const values = {
      clientName: NAME,
      courseName: String(NAME_COURSE).replace(/\,\-\s\(\)\_\:\/\\/g,''),
      price: VALUE_FINAL,
      financialDate: moment().format('YYMM'), // String(DATES_END).replace(/\,/g,''), 
      idFinancial: String(IDS).replace(/\,/g,''), 
      idClient: ID_CLIENT,
      // emailFrom: '',
      emailTo: emailTest,
      year,
      month: String(month).padStart(2, '0'),
      collection: datas,
    };

    const url = urlServer + '/payment/recovery/send-mailer-testing';
    const data = await axios.post(url, values);
    const result = data.data || '';

    console.log(data);

    item.style.opacity = 1;

    if (String(result).indexOf('250 2.0.0 OK') > -1) {

      item.style.backgroundColor = 'blue';
      item.style.color = 'white';
  
    } else {

      item.style.backgroundColor = 'orange';
      item.style.color = 'white';
      window.alert(result);

    }
  }

  async function sendMail(index, datas) {

    const item = document.getElementById(`item${index}`);
    item.style.opacity = 0.2;

    const { ID_CLIENT, NAME, EMAIL, EMAIL_FINANCIAL, VALUE_FINAL, NAME_COURSE, DATES_END, IDS } = datas || {};

    const values = {
      clientName: NAME,
      courseName: String(NAME_COURSE).replace(/\,\-\s\(\)\_\:\/\\/g,''),
      price: VALUE_FINAL,
      financialDate: moment().format('YYMM'), // String(DATES_END).replace(/\,/g,''), 
      idFinancial: String(IDS).replace(/\,/g,''), 
      idClient: ID_CLIENT,
      // emailFrom: '',
      emailTo: EMAIL_FINANCIAL || EMAIL,  
      year,
      month: String(month).padStart(2, '0'),
    };

    const url = urlServer + '/payment/recovery/send-mailer';
    const data = await axios.post(url, values);
    const result = data.data || '';

    console.log(data);

    item.style.opacity = 1;

    if (String(result).indexOf('250 2.0.0 OK') > -1) {

      item.style.backgroundColor = 'green';
      item.style.color = 'white';
  
    } else {

      item.style.backgroundColor = 'orange';
      item.style.color = 'white';
      window.alert(result);

    }
  }

  function sendAllEmails(idx) {
    let i = idx || 0;
    setTimeout(() => {
      const values = listFinancial[i];
      // const item = document.getElementById(`item${i}`);
      // item && (item.style.opacity = 0.2);
      // console.log(item, i)
      sendMail(i, values);
      i++;
      if(i < listFinancial.length) {
        sendAllEmails(i);
      }
    }, 1500);
  }

  function sendAllEmailsTesting(idx) {
    let i = idx || 0;
    setTimeout(() => {
      const values = listFinancial[i];
      // const item = document.getElementById(`item${i}`);
      // item && (item.style.opacity = 0.2);
      // console.log(item, i)
      sendMailTesting(i, values);
      i++;
      if(i < listFinancial.length && i < 10) {
        sendAllEmailsTesting(i);
      } else {
        window.alert('Teste concluído. Verifique seu e-mail.');
      }
    }, 1500);
  }

  function toLowercase(value) {
    return String(value).toLowerCase();
  }
  
  function currentMoney(value, setSymbol){
    // com R$
    // var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    // sem R$
    // var f2 = atual.toLocaleString('pt-br', {minimumFractionDigits: 2});
    return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00' ;
    // return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
  }

  useEffect(() => {
    listStudents(year, month);
  }, [year, month]);

  useEffect(() => {

    let y = [];
    const fy = thisYear + 1;
    for(let i = fy; i > fy - 10; i-- ){
      y.push(i);
    }
    setYearGen(y);
    setYear(thisYear);
    setMonth(thisMonth);

    // const month = moment().format('MM');
    listStudents(thisYear, thisMonth);

  }, []);

  return (
    <div style={styles.main}>

    <Title level={4}>Lista de Alunos</Title>

    <Content style={styles.content}>

      {wait && 'Carregando...'}

      {/* <Table dataSource={listFinancial} columns={columns} />; */}

      <div style={{fontWeight:700,marginTop:10}}>Ano e Mês</div>
      <div>
        <Select onChange={(value)=>setYear(value)} style={{ width: 200 }} value={year} >
          {yearGen.map( (el) => (
            <Option value={el} key={el}>{el}</Option>
          ))}
        </Select>
        <Select onChange={(value)=>setMonth(value)} style={{ width: 200 }} value={month} >
          {monthFull.map( (el, index) => (
            <Option value={index+1} key={el}>({index+1}) {el}</Option>
          ))}
        </Select>
        </div>

      <div style={{ padding: '10px 0' }}>
        <div style={{ width: '100px', float: 'left', paddingTop: '5px', }}>
          E-mail teste:
        </div>
        <Input value={emailTest} style={{ width: '250px' }} onChange={(e) => setEmailTest(e.currentTarget.value)}></Input>
        {/* <Button type="secondary" danger>
          Testar Envio (10 e-mails aleatórios)
        </Button> */}
        <Button type="primary" style={{ float: 'right' }} onClick={() => sendAllEmails(0)}>
          Enviar cobrança (todos abaixo)
        </Button>
        <Button type="primary" style={{ float: 'right' }} danger onClick={() => sendAllEmailsTesting(0)}>
          Teste (10 abaixo)
        </Button>
      </div>

      <div style={{ padding: '10px 0' }}>
        <a href="https://myaccount.google.com/lesssecureapps" target="_blank">Permitir envio de e-mail via gmail (ON)</a>
      </div>

      <div>
        {listFinancial && listFinancial.map((el, index) => {
          function setCourseName(v) {
            courseName = v;
            return v;
          }
          return (
            <>
              {el.NAME_COURSE !== courseName ? (
                <div style={{ borderBottom: '1px solid #aaa', fontSize: 11, backgroundColor: '#ddd', padding: '5px 7px' }}>
                  ({el.ID_PROJECT}) {setCourseName(el.NAME_COURSE)}
                </div>
              ) : ''}
              <div>
              </div>
              <div
                id={`item${index}`} 
                style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #DDD', padding: '7px 0' }} 
                key={index}>
                <div style={{ width: 50, paddingLeft: 10 }}>{index+1}</div>
                <div style={{ flex: 1, textTransform: 'capitalize' }}>
                  {toLowercase(el.NAME)}
                </div>
                {/* <div style={{ width: 300 }}>
                  <div style={{ fontSize: '8px', fontWeight: '700' }}>
                    {setCourseName(el.NAME_COURSE)}
                  </div>
                </div> */}
                <div style={{ width: 30, fontWeight: 'bold', fontSize: 10 }} title={el.JURIDIC_DESC}>
                  {el.JURIDIC ? (
                    <div style={{ borderRadius: 4, backgroundColor: '#777', color: '#fff', textAlign: 'center', padding: '2px 0' }}>
                      {el.JURIDIC}
                    </div>  
                  ) : null}
                </div>
                <div style={{ width: 80, textAlign: 'right', paddingRight: 10, fontSize: 9 }}>{el.VALUES_IN}</div>
                <div style={{ width: 80, textAlign: 'right', paddingRight: 10, fontSize: 9 }}>{currentMoney(el.VALUE_IN)}</div>
                <div style={{ width: 80, textAlign: 'right', paddingRight: 10, fontWeight: '600' }}>{currentMoney(el.VALUE_FINAL)}</div>
                {/* <div style={{ width: 80, textAlign: 'right', paddingRight: 10, fontWeight: '600' }}>{el.DATES_END}</div> */}
                <div style={{ width: 50 }}>{el.DATE_MONTH_DIFF}</div>
                <div style={{ width: 50 }}>{el.DATE_END_DIFF}</div>
                {/* <div style={{ width: 30 }}>{el.ID_CLIENT}</div>
                <div style={{ width: 30 }}>{el.ID}</div> */}
                <div style={{ width: 150 }}>
                  <Button type="primary" danger onClick={() => sendMailTesting(index, el)}>
                    Teste
                  </Button>
                  <Button type="primary" onClick={() => sendMail(index, el)}>
                    Enviar
                  </Button>
                </div>
                <div style={{ width: 70 }}>
                  <a href={`http://daybyday-server.gnnc.com.br/api/payment/recovery/get-qrcode/${year}/${String(month).padStart(2,'0w')}/${el.ID_CLIENT}`} target="_black">
                    QR Code
                  </a>
                </div>
              </div>
            </>
          )
        })}
      </div>

    </Content>

    </div>
  );
};

const styles = {
  main: {
    padding: '15px 25px',
  },
  content: {
    paddingBottom: 15,
  },
  subtitle: {
    fontWeight: 700,
    marginTop: 10,
  }
};

/*

ID	
ROWS_	
ID_CLIENT	
ID_PROJECT 
NAME_COURSE	
NAMES_COURSE	

IDS	
VALUES_IN	
NUMBERS	
NUMBERS_LETTER	
NAMES_GROUP	
DATES_END	
DATE_END_DIFF	
DATE_MONTH_DIFF	
DISCOUNT_PUNCTUALITY	
FINE_PERCENT	
FINE_PERCENT_TIME	
FINE_VALUE	
FINE_VALUE_PERCENT	
VALUE_FINAL	
VALUE_IN	

ENROLLMENT	
NAME	
EMAIL	
EMAIL_FINANCIAL	
JURIDIC	
JURIDIC_DESC	
DATE_BIRTH	
CPF_CNPJ	
RG_REGISTER	
RG_REGISTER_UF	
MOTHER	
ADDRESS	
ADDRESS_NUMBER	
ADDRESS_COMPLEMENT	
NEIGHBORHOOD	
ZIPCODE	
CITY	
STATE	
ISO_COUNTRY 	

*/