import { Content } from "antd/lib/layout/layout";
import Title from "antd/lib/typography/Title";
import React from "react";

export default function Welcome() {
  return (
    <Content style={styles.content}>
      <Title level={2}>Welcome</Title>
    </Content>
  );
}

const styles = {
  content: {
    padding: "15px 25px",
  },
};
